<template>
	<div class="q-px-md">
		<q-input
			type="text"
			v-model="noticeboard_name"
			outlined
			class="q-my-lg"
			color="primary"
			label="Audio Noticeboard name:"
		/>
	</div>
</template>

<script>
export default({
	name: 'AudioNotceboardName',
	computed:{
		noticeboard_name:{
			get(){
				return this.$store.getters.noticeboard_name;
			},
			set(val){
				this.$store.dispatch('updateNoticeboardName', val);
			}
		},
	},
})
</script>