<template>
	<div>
		<q-select
			v-if="called_from_noticeboard && !noticeboard_edit"
			class="q-px-md q-py-sm"
			color="primary"
			v-model="noticeboard_number"
			:options="available_noticeboard_numbers"
			label="Select your noticeboard:"
			:option-label="(item) => item === null ? 'Null value' : item.number"
			:readonly="available_noticeboard_numbers.length == 1"
		/>
		<q-select
			v-else-if="!called_from_noticeboard && !noticeboard_edit && noticeboard_ready > 0"
			class="q-px-md q-py-sm"
			color="primary"
			v-model="noticeboard_number"
			:options="created_noticeboards"
			label="Select your noticeboard:"
			:readonly="created_noticeboards.length == 1"
			:option-label="(item) => item === null ? 'Null value' : item.display_option"
		/>
		<div v-if="called_from_noticeboard && noticeboard_edit" class="q-px-md">
			<q-input
				readonly
				class="q-my-lg"
				color="primary"
				v-model="display_number"
				label="Selected noticeboard:"
			/>
		</div>
	</div>
</template>

<script>
import MessageFormAPI from '@/services/api/MessageForm.js';
import { mapGetters } from 'vuex';

export default {
	name: 'AudioNoticeboardNumbers',
	props:{
		called_from_noticeboard: Boolean,
	},
	data(){
		return{
			noticeboard_numbers: [],
			audio_noticeboard_numbers: [],
			created_noticeboards: [],
			available_noticeboard_numbers: [],
			noticeboard_ready: false,
			available_created_noticeboard: []
		}
	},
	computed:{
		...mapGetters(['current_CG', 'noticeboard_edit', 'noticeboard_number']),
		noticeboard_number:{
			get(){
				return this.$store.getters.noticeboard_number;
			},
			set(val){
				this.$store.dispatch('updateNoticeboardNumber', val);
			}
		},
		display_number() {
			if(this.noticeboard_number) {
				return this.noticeboard_number.number;
			}
		}
	},
	async created(){
		this.noticeboard_numbers = JSON.parse(this.current_CG.customer_group.settings).noticeboard_numbers;
		this.available_created_noticeboard = await MessageFormAPI.listAudioNoticeboards();
		let available_created_noticeboard_numbers = []

		if(!this.called_from_noticeboard){
			for (let noticeboard in this.available_created_noticeboard) {
				for (let noticeboard_num in this.noticeboard_numbers) {
					if (this.available_created_noticeboard[noticeboard]['phone_number']['number'] == this.noticeboard_numbers[noticeboard_num]['number']) {
						this.noticeboard_numbers[noticeboard_num]['name'] = this.available_created_noticeboard[noticeboard]['name'];
						this.noticeboard_numbers[noticeboard_num]['display_option'] = this.available_created_noticeboard[noticeboard]['name'] + " (" + this.available_created_noticeboard[noticeboard]['phone_number']['number'] + ") ";
						this.created_noticeboards.push(this.noticeboard_numbers[noticeboard_num]);
					}
				}
			}
		}else if(this.called_from_noticeboard) {
			for(let av_number of this.available_created_noticeboard){
				available_created_noticeboard_numbers.push(av_number['phone_number']['number'])
			}

			if(!this.noticeboard_edit){
				for (let noticeboard_num of this.noticeboard_numbers) {
					if (this.available_created_noticeboard){
						if(available_created_noticeboard_numbers.indexOf(noticeboard_num['number']) === -1){
							this.available_noticeboard_numbers.push(noticeboard_num)
						}
					}else{
						this.available_noticeboard_numbers.push(noticeboard_num)
					}
				}

			}else{
				this.available_noticeboard_numbers = this.noticeboard_number
			}
		}
		if(this.created_noticeboards && this.created_noticeboards.length === 1){
			this.$store.dispatch('updateNoticeboardNumber', this.created_noticeboards[0]);
		}

		if(this.available_noticeboard_numbers && this.available_noticeboard_numbers.length === 1){
			this.$store.dispatch('updateNoticeboardNumber', this.available_noticeboard_numbers[0]);
		}
	},
	watch:{
		available_created_noticeboard: {
			deep: true,
			handler() {
				this.noticeboard_ready = !!this.available_created_noticeboard.length;
			}
		}
	},
}
</script>