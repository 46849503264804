<template>
	<div>
		<AudioNoticeboardMessageType />
	</div>
</template>

<script>
import AudioNoticeboardMessageType from '@/components/message_forms/audio_noticeboard_tab/AudioNoticeboardMessageType.vue';

export default {
	name: 'AudioNoticeboardVoiceSettings',
	components:{
		AudioNoticeboardMessageType,
	},
}
</script>
