<template>
	<div>
		<q-item tag="label">
			<q-item-section>
				<q-item-label class="text-left">Use default voice</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle v-model="default_noticeboard_message_voice" />
			</q-item-section>
		</q-item>
		<div class="q-mx-md">
			<q-select
				v-if="!default_noticeboard_message_voice"
				class="q-my-lg"
				color="primary"
				v-model="noticeboard_language"
				:options="people_options"
				:option-label="(item) => item.value"
				label="Choose voice for your voice message"
			/>
		</div>
	</div>
</template>

<script>
import message_forms_utils from '@/utils/message_forms.js'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'AudioNoticeboardMessageType',
	data() {
		return {
			people_options: [],
		}
	},
	computed: {
		...mapGetters(['current_CG', 'people']),
		noticeboard_language: {
			get() {
				return this.$store.getters.noticeboard_language
			},
			set(val) {
				this.updateNoticeboardLanguage(val)
			},
		},
		default_noticeboard_message_voice: {
			get() {
				return this.$store.getters.default_noticeboard_message_voice
			},
			set(val) {
				this.updateDefaultNoticeboardMessageVoice(val)
			},
		},
	},
	methods: {
		...mapActions([
			'updateNoticeboardLanguage',
			'updateDefaultNoticeboardMessageVoice',
			'updatePeopleList',
		]),
	},
	created() {
		this.updatePeopleList()
		this.people_options = this.people.length
			? (this.people_options = this.people)
			: message_forms_utils.default_people()

		let customer_group_settings = {}
		if (this.current_CG.customer_group.settings) {
			customer_group_settings = JSON.parse(
				this.current_CG.customer_group.settings
			)
		}
		if (!Object.keys(this.noticeboard_language).length) {
			if (customer_group_settings.default_tts_voice) {
				this.updateNoticeboardLanguage(
					customer_group_settings.default_tts_voice
				)
			} else {
				const language = this.people.length
					? this.people[1]
					: this.people_options[1]
				this.updateNoticeboardLanguage(language)
			}
		}
	},
}
</script>
