<template>
	<div
		v-if="
			(!called_from_noticeboard && show_noticeboard) ||
			(called_from_noticeboard && check_sender_permission)
		"
		class="router-view-wrapper"
	>
		<div
			:class="{'data-wrapper': !called_from_noticeboard}"
		>
			<div v-if="!called_from_noticeboard">
				<q-item class="q-m" tag="label">
					<q-item-section>
						<q-item-label>Leave a message on the audio noticeboard:</q-item-label>
					</q-item-section>
					<q-item-section avatar>
						<q-toggle
							color="primary"
							v-model="use_noticeboard"
						/>
					</q-item-section>
				</q-item>
			</div>
			<AudioNotceboardName v-if="called_from_noticeboard" />

			<AudioNoticeboardNumbers
				v-if="use_noticeboard || called_from_noticeboard"
				:called_from_noticeboard='called_from_noticeboard'
			/>

			<AudioNoticeboardVoiceSettings v-if="use_noticeboard || called_from_noticeboard" />
			<AudioNoticeboardContent v-if="use_noticeboard || called_from_noticeboard" />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AudioNoticeboardNumbers from "@/components/message_forms/audio_noticeboard_tab/AudioNoticeboardNumbers.vue";
import AudioNoticeboardVoiceSettings from "@/components/message_forms/audio_noticeboard_tab/AudioNoticeboardVoiceSettings.vue";
import AudioNoticeboardContent from "@/components/message_forms/audio_noticeboard_tab/AudioNoticeboardContent.vue";
import AudioNotceboardName from "@/components/scenarios/audio_noticeboard/AudioNotceboardName.vue";

export default {
	name: "MessageFormNoticeboard",
	components: {
		AudioNoticeboardNumbers,
		AudioNoticeboardVoiceSettings,
		AudioNoticeboardContent,
		AudioNotceboardName,
	},
	props: {
		called_from_noticeboard: Boolean
	},
	data() {
		return {
			show_noticeboard: false,
			check_seder_permission: false,
		};
	},
	computed: {
		...mapGetters([
			"scenario_type",
			"current_CG",
			"logged_user"
		]),
		use_noticeboard: {
			get(){
				return this.$store.getters["use_noticeboard"]
			},
			set(val){
				if (!val) {
					this.updateNoticeboardNumber({});
					this.updateDefaultNoticeboardMessageVoice(true);
					this.updateNoticeboardLanguage({});
					this.updateNoticeboardMessage("");
				}
				this.updateUseNoticeboard(val)
			}
		}
	},
	methods: {
		...mapActions([
			"updateNoticeboardNumber",
			"updateDefaultNoticeboardMessageVoice",
			"updateNoticeboardLanguage",
			"updateNoticeboardMessage",
			"updateUseNoticeboard"
		]),
		getMessage() {
			const errors = this.$store.getters.errors_per_tab['Noticeboard'];
			for (let error of errors){
				this.$q.notify({
					timeout: 6700,
					message: error,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
	},
	created() {
		if (!this.current_CG.customer_group.settings) {
			this.customer_group_settings = {};
		} else {
			this.customer_group_settings = JSON.parse(
				this.current_CG.customer_group.settings
			);
		}
		const has_noticeboard_permission = this.current_CG.root_customer_group_permissions.includes(
			"Audio noticeboard"
		);
		const has_noticeboard_numbers =
			this.customer_group_settings.noticeboard_numbers &&
			!!this.customer_group_settings.noticeboard_numbers.length;
		this.show_noticeboard =
			(this.scenario_type == "broadcast" || this.scenario_type == "sequence") &&
			(this.logged_user.customer_permission != "SENDER" || (
			this.logged_user.customer_permission === 'SENDER' &&
			this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit content')
		))&&
			has_noticeboard_permission && has_noticeboard_numbers;
		this.check_sender_permission = this.logged_user.customer_permission !== 'SENDER' || (
			this.logged_user.customer_permission === 'SENDER' &&
			this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit content')
		)

		this.getMessage()
	}
};
</script>
