<template>
	<div class="q-px-md">
		<SuccessErrorMessage :message="message" />
		<q-input
			class="q-mt-sm"
			outlined
			color="primary"
			id="noticeboard_message"
			v-model="noticeboard_message"
			type="textarea"
			label="Audio noticeboard message content"
		/>
		<div class="q-mb-lg row">
			<preview-button
				:loading="show_machine_spinner"
				:is_disabled='noticeboard_message'
				@playTTS=playTTS
			/>
		</div>
	</div>
</template>

<script>
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";
import { mapGetters } from 'vuex';
import MessageFormAPI from '@/services/api/MessageForm.js';
import PreviewButton from '@/components/datasets/utils/PreviewButton.vue'

export default {
	name: 'AudioNoticeboardContent',
	components: {
		SuccessErrorMessage,
		PreviewButton
	},
	data() {
		return {
			show_machine_spinner: false,
			message: {}
		}
	},
	computed: {
		...mapGetters(['noticeboard_language']),
		noticeboard_message: {
			get() {
				return this.$store.getters.noticeboard_message;
			},
			set(val) {
				this.$store.dispatch('updateNoticeboardMessage', val);
			}
		}
	},
	methods: {
		async playTTS() {
			let message = {};
			this.message = {};
			try {
				message = { 'message': this.noticeboard_message, 'tts_voice': this.noticeboard_language.name };
			} catch (error) {
				this.message = {
					type: 'error',
					text: 'Please choose a voice for your voice message'
				};
				return;
			}

			this.show_machine_spinner = true;
			const response = await MessageFormAPI.getTTSAudio(message);
			this.show_machine_spinner = false;
			let audio = new Audio(response);
			audio.play();
		}
	},
}
</script>


